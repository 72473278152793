import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthProvider } from '@providers/IAuthProvider';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authProvider = inject(AuthProvider);
  router = inject(Router);

  handleError(error) {
    let handled = false; // can be used if handled (like redirected to another URL)
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // Error Event
      } else {
        switch (error.status) {
          case 401: // login
            handled = false;
            break;
          case 403: // forbidden
            handled = false;
            break;
        }
      }
    } else {
      // Other Errors
    }

    if (handled) {
      // return back
      return of(error);
    } else {
      // throw error back to the subscriber
      return throwError(error);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // --- Ensure we send the token only to routes which are secured ---
    if (!environment.secureRoutes.find(x => req.url.startsWith(x))) {
      return next.handle(req);
    }

    const nibioToken = this.authProvider.nibioToken;

    if (!nibioToken) {
      req = req.clone({ headers: req.headers.set('clienturl', document.baseURI) });
      return next.handle(req).pipe(
        catchError(error => {
          return this.handleError(error);
        }),
      );
    }

    // --- Set last activity timestamp on all request exept on isUserAuthenticated  ---
    if (!req.url.includes('isUserAuthenticated')) {
      localStorage.setItem('lastActivityTimeStamp', new Date().getTime().toString());
    }

    const request = req.clone({
      headers: req.headers.set('nibiotoken', 'Bearer ' + nibioToken).set('clienturl', document.baseURI),
    });

    return next.handle(request).pipe(
      catchError(error => {
        return this.handleError(error);
      }),
    );
  }
}
