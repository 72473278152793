import { Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { authResolver } from '@resolvers/auth.resolver';
import { MapResolver } from '@resolvers/map.resolver';

export const appRoutes: Routes = [
  {
    data: { title: 'Søk gård' },
    loadComponent: () => import('@components/front-page/front-page.component').then(m => m.FrontPageComponent),
    path: 'search',
    resolve: { isAuthenticated: authResolver },
  },
  {
    data: { grunneiendom: false, title: 'Gårdskart - NIBIO' },
    loadComponent: () => import('src/app/gk.component').then(m => m.GkComponent),
    path: 'landbrukseiendom/:komm/:gnr/:bnr/:fnr',
    resolve: { isAuthenticated: authResolver, propertyData: MapResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    data: { grunneiendom: true, title: 'Gårdskart - NIBIO' },
    loadComponent: () => import('src/app/gk.component').then(m => m.GkComponent),
    path: 'grunneiendom/:komm/:gnr/:bnr/:fnr',
    resolve: { isAuthenticated: authResolver, propertyData: MapResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    loadComponent: () => import('@components/auth-callback/auth-callback').then(m => m.AuthCallbackComponent),
    path: 'signInCallback',
  },
  {
    loadComponent: () => import('@components/signout/signout.component').then(m => m.SignoutComponent),
    path: 'signOutCallback',
  },
  {
    canActivate: [AuthGuard],
    data: { title: 'Bruker registrering' },
    loadComponent: () =>
      import('@components/user-registration/registration-page/registration-page.component').then(
        m => m.UserRegistrationPageComponent,
      ),
    path: 'bruker-registrering',
  },
  {
    canActivate: [AuthGuard],
    data: { title: 'Min side' },
    loadComponent: () =>
      import('@components/bruker-info/min-side-page/min-side-page.component').then(m => m.MinSidePageComponent),
    path: 'min-side',
  },
  {
    path: '**',
    redirectTo: '/search',
  },
];
