import { GkAction } from '@store/gk/gk-action';

export const UPDATE_NULL_GEOMETRY_STATE = 'UPDATE_NULL_GEOMETRY_STATE';

export function nullGeometryReducer(state: string, action: GkAction) {
  switch (action.type) {
    case UPDATE_NULL_GEOMETRY_STATE:
      return action.payload;
    default:
      return state;
  }
}
