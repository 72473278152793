import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, createUrlTreeFromSnapshot, UrlTree } from '@angular/router';
import { UserRegistrationPageComponent } from '@components/user-registration/registration-page/registration-page.component';
import { AuthProvider } from '@providers/IAuthProvider';
import { UserService } from '@services/user.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  #authProvider = inject(AuthProvider);
  #userService = inject(UserService);

  #isAuthenticated(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const urlTree = createUrlTreeFromSnapshot(route, ['/search']);
    return this.#authProvider.checkAuth$.pipe(
      tap(isAuth => {
        // Refresh user account to get all user details like username etc.
        if (
          isAuth &&
          route.url[0].path !== 'bruker-registrering' &&
          route.component !== UserRegistrationPageComponent
        ) {
          this.#userService.refreshUserData();
        }
      }),
      map(isAuth => {
        return isAuth || urlTree;
      }),
      catchError(() => {
        return of(urlTree);
      }),
    );
  }

  canActivate(route: ActivatedRouteSnapshot /* , state: RouterStateSnapshot */) {
    return this.#isAuthenticated(route);
  }
}
