import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { UserRegistrationPageComponent } from '@components/user-registration/registration-page/registration-page.component';
import { AuthProvider } from '@providers/IAuthProvider';
import { UserService } from '@services/user.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

export const authResolver: ResolveFn<Observable<boolean>> = (route: ActivatedRouteSnapshot) => {
  const authProvider = inject(AuthProvider);
  const userService = inject(UserService);

  try {
    return authProvider.checkAuth$.pipe(
      map(isAuth => {
        if (
          isAuth &&
          route.url[0].path !== 'bruker-registrering' &&
          route.component !== UserRegistrationPageComponent
        ) {
          userService.refreshUserData();
        }
        return isAuth;
      }),
    );
  } catch {
    return of(false);
  }
};
