/// <reference types="@angular/localize" />

import { DOCUMENT, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeNb from '@angular/common/locales/nb';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  PreloadAllModules,
  provideRouter,
  Router,
  RouteReuseStrategy,
  RouterModule,
  withPreloading,
} from '@angular/router';
import { TreeviewModule } from '@ccondrup/ngx-treeview';
import { I18n } from '@components/own-layers/i18n';
import { RoutingComponent } from '@components/routing/routing.component';
import { ApiConfig } from '@config/api.config';
import { appRoutes } from '@config/app-routes.config';
import { DomChangeDirective } from '@directives/dom-change.directive';
import { environment } from '@environments/environment';
import { AuthGuard } from '@guards/auth.guard';
import { AuthInterceptor } from '@interceptors/auth-interceptor';
import { StoreModule } from '@ngrx/store';
import { BackendAuthClient } from '@providers/BackendAuthClient';
import { AuthProvider } from '@providers/IAuthProvider';
import { MapResolver } from '@resolvers/map.resolver';
import { AreaStatisticsService } from '@services/area-statistics.service';
import { BackgroundMapsService } from '@services/background-maps.service';
import { ConfigService } from '@services/config.service';
import { DrawLayerService } from '@services/draw-layer.service';
import { DrawService } from '@services/draw.service';
import { EndPointService } from '@services/endpoint.service';
import { ErrorService } from '@services/error.service';
import { GaardskartService } from '@services/gaardskart.service';
import { ImportedFileMapService } from '@services/imported-file-maps.service';
import { LayerTreeService } from '@services/layer-tree.service';
import { LegendService } from '@services/legend.service';
import { MapCanvasService } from '@services/map-canvas.service';
import { MapService } from '@services/map.service';
import { ParamService } from '@services/param.service';
import { PrintMapService } from '@services/print-map.service';
import { PrintService } from '@services/print.service';
import { PropertyService } from '@services/property.service';
import { RouteReuseService } from '@services/route-reuse-service';
import { TicketService } from '@services/ticket.service';
import { UserDataService } from '@services/user-data.service';
import { UserService } from '@services/user.service';
import { StoreSetup } from '@store/store-setup';
import { AngularSplitModule } from 'angular-split';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MarkdownModule, MarkdownService } from 'ngx-markdown';

registerLocaleData(localeNb);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(RoutingComponent, {
  providers: [
    importProvidersFrom(
      AccordionModule,
      AlertModule,
      AlertModule.forRoot(),
      AngularSplitModule,
      BrowserModule,
      FormsModule,
      MarkdownModule.forRoot(),
      ModalModule.forRoot(),
      PopoverModule,
      PopoverModule.forRoot(),
      ReactiveFormsModule,
      RouterModule.forRoot([]),
      StoreModule.forRoot(StoreSetup, {
        runtimeChecks: {
          strictActionImmutability: false,
          strictStateImmutability: false,
        },
      }),
      TabsModule,
      TooltipModule.forRoot(),
      TreeviewModule.forRoot(),
      TypeaheadModule,
      TypeaheadModule.forRoot(),
      environment.imports,
    ),
    ApiConfig,
    AreaStatisticsService,
    AuthGuard,
    BackgroundMapsService,
    ConfigService,
    DomChangeDirective,
    DrawLayerService,
    DrawService,
    EndPointService,
    ErrorService,
    GaardskartService,
    I18n,
    ImportedFileMapService,
    LayerTreeService,
    LegendService,
    MapCanvasService,
    MapResolver,
    MapService,
    MarkdownService,
    ParamService,
    PrintMapService,
    PrintService,
    PropertyService,
    TicketService,
    UserDataService,
    UserService,
    {
      deps: [Router, HttpClient, BsModalService, DOCUMENT, MapCanvasService, UserService],
      provide: AuthProvider,
      useClass: BackendAuthClient,
    },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReuseService,
    },
    { provide: LOCALE_ID, useValue: 'nb' },
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(appRoutes, withPreloading(PreloadAllModules)),
  ],
}).catch(err => console.error(err));
