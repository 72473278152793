import { addedPropertyReducer } from '@store/added-property/added-property.reducer';
import { backgroundReducer } from '@store/bg/bg.reducer';
import { cancelReducer } from '@store/cancel-gk-requests/cancelGkRequests.reducer';
import { flushAddedPropertyReducer } from '@store/flush-added-property/flushAddedProperty.reducer';
import { flushMainPropertyReducer } from '@store/flush-main-property/flushMainProperty.reducer';
import { indicateAreaReducer } from '@store/indicate-area/indicateArea.reducer';
import { loadingReducer } from '@store/loading/loading.reducer';
import { mapLayerReducer } from '@store/map-layer/map-layer.reducer';
import { nullGeometryReducer } from '@store/null-geometry/null-geometry.reducer';
import { advancedPrintModeReducer } from '@store/print-mode/print-mode.reducer';
import { propertyLevelReducer } from '@store/property-level/propertyLevel.reducer';
import { showAlertPropertyReducer } from '@store/show-alert-property/showAlertProperty.reducer';
import { teigReducer } from '@store/teig/teig.reducer';
import { activeToolReducer } from '@store/tool-state/tool_state.reducer';

export const StoreSetup: any = {
  activeTool: activeToolReducer,
  addedProperties: addedPropertyReducer,
  advancedPrintMode: advancedPrintModeReducer,
  bgLayer: backgroundReducer,
  cancelGkRequests: cancelReducer,
  flushAddedProperty: flushAddedPropertyReducer,
  flushMainProperty: flushMainPropertyReducer,
  indicateArea: indicateAreaReducer,
  loading: loadingReducer,
  mapLayer: mapLayerReducer,
  nullGeometry: nullGeometryReducer,
  propertyLevel: propertyLevelReducer,
  showAlertProperty: showAlertPropertyReducer,
  showAllTeiger: teigReducer,
};
